<template>
    <v-carousel 
        height="100%" 
        v-model="carousel"
        cycle
        interval="8000"
    >
        <v-carousel-item
        reverse-transition="fade-transition"
        transition="fade-transition"
        >
         <PromoPercent/>
        </v-carousel-item>
        <v-carousel-item
        reverse-transition="fade-transition"
        transition="fade-transition"
        >
         <PromoPromo/>
        </v-carousel-item>
    </v-carousel>
    
</template>
<script>
import PromoPercent from './PromoPercent'
import PromoPromo from './PromoPromo'
export default {    
    components:{PromoPercent,PromoPromo},
    data:()=>({
        carousel:1
    })
}
</script>
