<template>
    <div style="height:100%;background:black;">
        <v-container class="" style="height:100%">
           <v-row align="center" justify="center" style="height:100%">
                <v-col cols="12" sm="10" md="8" lg="8" xl="8">
                    
                    <v-card
                        dark
                        color="white"
                        elevation=""
                        max-width=""
                        rounded="xl"
                        id="lateral"
                    >   
                        
                        
                        <v-card-text>
                        <v-row class="grey--text text--darken-2">
                            <v-col  cols="12" md="4" lg="4" xl="4" >
                                <div style="">
                                    <img src="@/assets/images/oferta-especial.png" width="100%">
                                    
                                </div>
                            </v-col>
                            <v-col cols="12" md="8" lg="8" xl="8" >
                                <div class="text-center mt-5" :style="`font-weight:bold;word-break:normal;word-wrap:normal;font-size:${$vuetify.breakpoint.mobile?'4':'5'}em;font-family: 'Loved by the King', cursive; line-height:30pt;`">
                                    Salon de belleza la brujita
                                    <br>
                                    <br>
                                    <span style="font-size:.5em;font-family: 'Loved by the King', cursive; line-height:30pt;">
                                         Estetica, Barbería, Uñas.
                                    </span>
                                   
                                </div>
                            </v-col> 
                            
                            <v-col lg="8" class="text-center">
                                <span :style="`font-size:${$vuetify.breakpoint.mobile?'25':'50'}px;font-family: 'Loved by the King', cursive; line-height: ${$vuetify.breakpoint.mobile?'30':'60'}pt;`">
                                    Semana de 15 al 19 de <span style="font-weight:bold; font-size:1.2em;;font-family: 'Loved by the King', cursive;">febrero </span> corte de pelo, desde <span style="font-weight:bold; font-size:2em;;font-family: 'Loved by the King', cursive;">$ 60 </span>

                                    
                                </span>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <img src="@/assets/images/oferta-especial-red.png" width="100%" >
                                    
                            </v-col>
                            <v-col cols="12" md="8">
                                <div style="font-size:20px;color:black" class="text-center">
                                    <b>Aplican restricciones:</b>    <br>
                                    *   Solo para servicios agendados dentro del mes de febrero <br>
                                    *   Solo para servicios con "previa cita" <br>
                                    *   No aplica con otras promociones
                                </div>
                                
                            </v-col>
                            <v-col cols="12" md="4" class="text-center">
                                <span style="font-weight:bold;font-size:20px;color:black" >
                                    Solicita una cita 
                                </span>
                                
                                <div style="font-size:30px;color:black" >
                                    <v-icon color="">fab fa-whatsapp</v-icon>
                                    55 8598 6366
                                </div>
                                <div style="font-size:30px;color:black" >
                                    <v-icon color="">fa-phone</v-icon>
                                    55 1334 1838
                                </div>
                            </v-col>
                        </v-row>
                            
                            
                        </v-card-text>
                        <v-card-actions>
                            
                        </v-card-actions>
                        
                    </v-card>
                    
                </v-col>
            </v-row>
        </v-container>
        
        
        
        
    </div>
</template>
<script>
export default {
    
}
</script>